import React, { useState, useRef } from 'react';
import { ChevronDown, ChevronUp, FileText, TrendingUp, Bot, Waypoints, Gamepad2, Microscope, Webhook,  BarChart2, ChartScatter , ShieldCheck, Gamepad, CheckCircle, Activity, Database, X, Mail } from 'lucide-react';const ResourceCard = ({ 
  Icon, 
  title, 
  description, 
  buttonText, 
  buttonLink,
  extraContent, 
  isCollapsed = false, 
  showAcceptConditions = false 
}) => {
  const [isOpen, setIsOpen] = useState(!isCollapsed);
  const [accepted, setAccepted] = useState(false);

  const handleClick = () => {
    if (buttonLink) {
      window.open(buttonLink, '_blank');
    } else {
      setIsOpen(!isOpen);
    }
  };

  return (
    <div className="bg-white p-8 rounded-lg shadow-md">
      <Icon className="w-16 h-16 text-green-500 mb-6" />
      <h3 className="text-2xl font-semibold mb-4">{title}</h3>
      <p className="text-gray-600 mb-6">{description}</p>
      <button 
        className="bg-green-500 text-white px-6 py-3 rounded-full hover:bg-green-600 transition text-lg"
        onClick={handleClick}
      >
        {buttonText}
      </button>
      {!buttonLink && isOpen && extraContent && (
        <div className="mt-4 p-4 bg-gray-100 rounded">
          {extraContent}
          {showAcceptConditions && (
            <div className="mt-4">
              <label className="flex items-center">
                <input
                  type="checkbox"
                  checked={accepted}
                  onChange={() => setAccepted(!accepted)}
                  className="mr-2"
                />
                <span>I accept the Terms of Service</span>
              </label>
            </div>
          )}
        </div>
      )}
    </div>
  );
};


const HorizontalRoadmap = () => {
  const roadmapItems = [
    {
      phase: "01",
      title: "Foundation Launch",
      icon: <CheckCircle className="w-6 h-6" />,
      items: [
        "MVP launch",
        "Basic charting",
        "Financial simulations",
        "Basic AI features"
      ],
      status: "Completed"
    },
    {
      phase: "02",
      title: "Platform Enhancement",
      icon: <Database className="w-6 h-6" />,
      items: [
        "Additional data sources",
        "More simulation features",
        "More AI features",
        "V2 website upgrade"
      ],
      status: "In Progress"
    },
    {
      phase: "03",
      title: "Portfolio Innovation",
      icon: <BarChart2 className="w-6 h-6" />,
      items: [
        "Advanced portfolio analytics",
        "Expanded web capabilities",
        "Greater accessibility across various platforms and languages",
        "AI driven porfolio management agents"

      ],
      status: "Planned"
    },
    {
      phase: "04",
      title: "Advanced Features",
      icon: <Activity className="w-6 h-6" />,
      items: [
        "AI marketplace",
        "CEX listing",
        "Play to Train implementation",
        "Business and data analytics suite"
      ],
      status: "Upcoming"
    }
];

return (
  <div className="max-w-6xl mx-auto px-4">
    <h2 className="text-2xl md:text-3xl font-bold text-center mb-8 md:mb-16">Our Roadmap</h2>
    
    {/* Desktop Version */}
    <div className="hidden md:block relative">
      {/* Connecting line */}
      <div className="absolute top-8 left-0 right-0 h-1 bg-gradient-to-r from-blue-500 to-green-500" />
      
      <div className="flex justify-between relative">
        {roadmapItems.map((item, index) => (
          <div key={item.phase} className="relative px-4 w-1/4">
            {/* Vertical connecting line */}
            <div className="absolute top-8 left-1/2 w-1 h-8 bg-gradient-to-b from-blue-500 to-green-500 -translate-x-1/2" />
            
            {/* Phase number circle */}
            <div className="absolute top-4 left-1/2 -translate-x-1/2 w-8 h-8 rounded-full bg-gradient-to-r from-blue-500 to-green-500 flex items-center justify-center text-white font-bold text-sm">
              {item.phase}
            </div>

            <div className="mt-20 bg-white rounded-lg p-6 shadow-lg hover:shadow-xl transition-all duration-300 transform hover:-translate-y-1">
              <div className="text-green-500 mb-3">{item.icon}</div>
              <h3 className="text-lg font-semibold mb-4">{item.title}</h3>
              <ul className="space-y-2 text-sm">
                {item.items.map((listItem, i) => (
                  <li key={i} className="flex items-start">
                    <span className="w-1.5 h-1.5 bg-gradient-to-r from-blue-500 to-green-500 rounded-full mr-2 flex-shrink-0 mt-2" />
                    <span>{listItem}</span>
                  </li>
                ))}
              </ul>
              <div className={`mt-4 text-sm font-medium ${
                item.status === 'Completed' ? 'text-green-500' :
                item.status === 'In Progress' ? 'text-blue-500' :
                'text-gray-500'
              }`}>
                {item.status}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>

    {/* Mobile Version */}
    <div className="md:hidden space-y-8">
      {roadmapItems.map((item, index) => (
        <div key={item.phase} className="relative">
          {/* Timeline connector */}
          {index !== roadmapItems.length - 1 && (
            <div className="absolute left-4 top-16 bottom-0 w-0.5 bg-gradient-to-b from-blue-500 to-green-500" />
          )}

          <div className="flex items-start">
            {/* Phase number circle */}
            <div className="z-10 flex-shrink-0 w-8 h-8 rounded-full bg-gradient-to-r from-blue-500 to-green-500 flex items-center justify-center text-white font-bold text-sm">
              {item.phase}
            </div>

            {/* Content card */}
            <div className="ml-6 bg-white rounded-lg p-6 shadow-lg flex-grow">
              <div className="flex items-center mb-4">
                <div className="text-green-500 mr-3">{item.icon}</div>
                <h3 className="text-lg font-semibold">{item.title}</h3>
              </div>
              <ul className="space-y-3">
                {item.items.map((listItem, i) => (
                  <li key={i} className="flex items-start">
                    <span className="w-1.5 h-1.5 bg-gradient-to-r from-blue-500 to-green-500 rounded-full mr-2 flex-shrink-0 mt-2" />
                    <span className="text-sm">{listItem}</span>
                  </li>
                ))}
              </ul>
              <div className={`mt-4 text-sm font-medium ${
                item.status === 'Completed' ? 'text-green-500' :
                item.status === 'In Progress' ? 'text-blue-500' :
                'text-gray-500'
              }`}>
                {item.status}
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  </div>
);
};
const NavLink = ({ targetId, children, onNavigate }) => {
  const handleClick = () => {
    const element = document.getElementById(targetId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
    if (onNavigate) {
      onNavigate();
    }
  };

  return (
    <button onClick={handleClick} className="text-white hover:text-green-300 transition">
      {children}
    </button>
  );
};


const FeatureCard = ({ Icon, title, description, details }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition">
      <Icon className="w-12 h-12 text-green-500 mb-4" />
      <h3 className="text-xl font-semibold mb-2">{title}</h3>
      <p className="text-gray-600 mb-4">{description}</p>
      <button 
        className="text-green-500 hover:text-green-600 flex items-center"
        onClick={() => setIsOpen(!isOpen)}
      >
        {isOpen ? 'Hide Details' : 'More Details'}
        {isOpen ? <ChevronUp className="ml-2 w-4 h-4" /> : <ChevronDown className="ml-2 w-4 h-4" />}
      </button>
      {isOpen && (
        <div className="mt-4 p-4 bg-gray-100 rounded">
          {details}
        </div>
      )}
    </div>
  );
};




const FAQItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="border-b border-gray-200 py-4">
      <button
        className="flex justify-between items-center w-full text-left px-4 md:px-6" // Added padding here
        onClick={() => setIsOpen(!isOpen)}
      >
        <h4 className="text-lg font-semibold">{question}</h4>
        {isOpen ? <ChevronUp className="w-5 h-5" /> : <ChevronDown className="w-5 h-5" />}
      </button>
      {isOpen && (
        <p className="mt-2 text-gray-600 px-4 md:px-6"> {/* Added padding here */}
          {answer}
        </p>
      )}
    </div>
  );
};

const ExtendedFAQSection = () => {
  const faqItems = [
    {
      question: "How do you buy the Glades token?",
      answer: (
        <>
          Buy $GLDS on {" "}<a href="https://www.uniswap.com" target="_blank" rel="noopener noreferrer" style={{color: "darkblue"}}>Uniswap</a>
        </>
      )
    },
    {
      question: "Where does the tax go?", 
      answer: "We have a 5/5 buy and sell tax, it goes towards business operations."
    },
    {
      question: "How much GPU power do I need to train my models?",
      answer: "You can train small models without a GPU, otherwise you can train using our hardware."
    },
    {
      question: "How do I use Glades?",
      answer: <>You can access Glades either via our API or by {" "}<a href="https://github.com/Gattic/gattic-installer" target="_blank" rel="noopener noreferrer" style={{color: "darkblue"}}>downloading</a> and running our program.</>
    },
    {
      question: "How do I run Glades?",
      answer: (   
              <>
              You can run Glades using our code on {" "}<a href="https://github.com/Gattic/glades-ml" target="_blank" rel="noopener noreferrer" style={{color: "darkblue"}}>GitHub</a>
      </>  
      )
    },
    {
      question: "How do I utilise the Play to Train?",
      answer: "Our Play to Train is coming soon please join the discord for updates."
    },
    {
      question: "How do I connect my wallet?",
      answer: "In the future you will be able to connect various wallets such as phantom and metmask."
    },
  ];

  return (
    <section id="faq" className="bg-white py-16">
      <div className="container mx-auto px-4"> {/* Added container padding */}
        <h2 className="text-3xl font-bold text-center mb-12">Frequently Asked Questions</h2>
        <div className="max-w-3xl mx-auto space-y-6">
          {faqItems.map((item, index) => (
            <FAQItem key={index} question={item.question} answer={item.answer} />
          ))}
        </div>
      </div>
    </section>
  );
};

const FooterPage = ({ isOpen, onClose, initialSection = 'privacy' }) => {
  const [activeSection, setActiveSection] = useState(initialSection);

  if (!isOpen) return null;

  const renderContent = () => {
    switch (activeSection) {

      case 'privacy':
      return (
        <div className="prose max-w-none">
          <h3 className="text-2xl font-semibold mb-4">Privacy Policy</h3>
          <p className="text-sm text-gray-600 mb-6">Last Updated: November 21, 2024</p>
          
          <div className="space-y-8">
            <section>
              <h4 className="text-xl font-medium mb-3">1. Introduction</h4>
              <p>At GladesAI, we take your privacy seriously. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our AI development platform, including our neural network training tools, data processing services, model marketplace, and Play to Train™ system.</p>
            </section>

            <section>
              <h4 className="text-xl font-medium mb-3">2. Information We Collect</h4>
              <p className="font-medium mb-2">2.1 Account Information</p>
              <p>When you register for GladesAI, we collect your name, email address, professional details, and account credentials.</p>
              
              <p className="font-medium mt-4 mb-2">2.2 Training Data</p>
              <p>We collect the data you provide for training AI models, including datasets, parameters, and model configurations. This may include financial data, time series data, and other structured or unstructured data you upload to our platform.</p>
              
              <p className="font-medium mt-4 mb-2">2.3 Usage Information</p>
              <p>We collect information about how you use our services, including API calls, compute resource usage, model training sessions, and Play to Train™ activities.</p>
              
              <p className="font-medium mt-4 mb-2">2.4 Technical Data</p>
              <p>We automatically collect device information, IP addresses, browser type, system logs, and other technical data necessary for service operation and security.</p>
            </section>

            <section>
              <h4 className="text-xl font-medium mb-3">3. How We Use Your Information</h4>
              <p className="font-medium mb-2">3.1 Service Provision</p>
              <p>We use your information to provide and improve our AI development services, process your requests, and maintain your account.</p>

              <p className="font-medium mt-4 mb-2">3.2 Model Training</p>
              <p>Your training data is used solely for the purpose of training and improving your AI models. We maintain strict data isolation between users and projects.</p>

              <p className="font-medium mt-4 mb-2">3.3 Platform Improvement</p>
              <p>We analyze usage patterns and performance metrics to enhance our platform's functionality, optimize resource allocation, and improve user experience.</p>

              <p className="font-medium mt-4 mb-2">3.4 Play to Train™</p>
              <p>Information collected during Play to Train™ sessions is used to validate training quality, calculate rewards, and improve training methodologies.</p>
            </section>

            <section>
              <h4 className="text-xl font-medium mb-3">4. Data Security</h4>
              <p>We implement industry-standard security measures to protect your information, including encryption, access controls, and regular security audits. All data is processed in secure environments with appropriate safeguards against unauthorized access.</p>
            </section>

            <section>
              <h4 className="text-xl font-medium mb-3">5. Data Sharing and Disclosure</h4>
              <p className="font-medium mb-2">5.1 Third-Party Service Providers</p>
              <p>We may share your information with trusted service providers who assist in operating our platform, processing payments, or providing customer support. These providers are bound by strict confidentiality agreements.</p>

              <p className="font-medium mt-4 mb-2">5.2 Model Marketplace</p>
              <p>If you choose to publish models on our marketplace, certain information about your models and account may be visible to other users. You control what information is shared.</p>

              <p className="font-medium mt-4 mb-2">5.3 Legal Requirements</p>
              <p>We may disclose your information if required by law or in response to valid requests from public authorities.</p>
            </section>

            <section>
              <h4 className="text-xl font-medium mb-3">6. Your Rights and Choices</h4>
              <p>You have the right to access, correct, or delete your personal information. You can control how your data is used through your account settings and can opt-out of non-essential data collection. You may request data export or account deletion at any time.</p>
            </section>

            <section>
              <h4 className="text-xl font-medium mb-3">7. Data Retention</h4>
              <p>We retain your information for as long as necessary to provide our services and comply with legal obligations. Training data is retained according to your specified retention policies and can be deleted upon request.</p>
            </section>

            <section>
              <h4 className="text-xl font-medium mb-3">8. International Data Transfers</h4>
              <p>We may process your information in various locations where our facilities or service providers are located. We ensure appropriate safeguards are in place for international data transfers.</p>
            </section>

            <section>
              <h4 className="text-xl font-medium mb-3">9. Updates to Privacy Policy</h4>
              <p>We may update this Privacy Policy periodically to reflect changes in our practices or legal requirements. We will notify you of material changes and obtain consent where required.</p>
            </section>

            <section>
              <h4 className="text-xl font-medium mb-3">10. Contact Us</h4>
              <p>If you have questions about this Privacy Policy or our privacy practices, please contact our Data Protection Officer at:<br />
              Email: privacy@gladesai.ai</p>
              </section>
              </div>
              </div>
      );
        
  case 'terms':
    return (
      <div className="prose max-w-none">
        <h3 className="text-2xl font-semibold mb-4">Terms of Service</h3>
        <p className="text-sm text-gray-600 mb-6">Last Updated: November 21, 2024</p>
        
        <div className="space-y-8">
          <section>
            <h4 className="text-xl font-medium mb-3">1. Acceptance of Terms</h4>
            <p>By accessing or using GladesAI's services, including our website, AI development platform, model marketplace, and associated tools (collectively, the "Services"), you agree to be bound by these Terms of Service ("Terms"). If you are using the Services on behalf of an organization, you represent that you have the authority to bind that organization to these Terms.</p>
          </section>

          <section>
            <h4 className="text-xl font-medium mb-3">2. Service Description</h4>
            <p>GladesAI provides AI development, training, and deployment services, including but not limited to neural network development and training tools, data analysis and processing capabilities, model marketplace and distribution platform, Play to Train™ training system, and API access and integration tools.</p>
          </section>

          <section>
            <h4 className="text-xl font-medium mb-3">3. User Accounts and Security</h4>
            <p className="font-medium mb-2">Account Registration</p>
            <p>You must provide accurate, current, and complete information during registration. Maintain the security of your account credentials and notify us immediately of any unauthorized access. One person or entity per account unless explicitly authorized.</p>
            <p className="font-medium mt-4 mb-2">Account Security</p>
            <p>You are responsible for all activities under your account. Implement reasonable security measures for API keys and access tokens. Regular security audits may be conducted to ensure compliance.</p>
          </section>

          <section>
            <h4 className="text-xl font-medium mb-3">4. Data Privacy and Protection</h4>
            <p className="font-medium mb-2">Data Processing</p>
            <p>We process data in accordance with our Privacy Policy and applicable laws. You retain ownership of your data and grant us necessary licenses to provide the Services. You are responsible for obtaining required consents for data processing.</p>
            <p className="font-medium mt-4 mb-2">Data Security</p>
            <p>We implement industry-standard security measures. You must not upload malicious code or conduct security attacks. Regular backups are recommended but not guaranteed.</p>
          </section>

          <section>
            <h4 className="text-xl font-medium mb-3">5. Intellectual Property</h4>
            <p>You retain rights to your input data and custom models. Models trained using our platform are subject to our fair use policy. Our software, technology, and platform remain our exclusive property. License to use platform features is non-transferable.</p>
          </section>

          <section>
            <h4 className="text-xl font-medium mb-3">6. Usage Rules and Restrictions</h4>
            <p>Users agree not to violate laws or regulations, infringe on intellectual property rights, conduct unauthorized security testing, reverse engineer the Services, exceed rate limits or API quotas, use for weapons development or military applications, or create discriminatory or harmful AI systems.</p>
          </section>

          <section>
            <h4 className="text-xl font-medium mb-3">7. Play to Train™ Specific Terms</h4>
            <p>Reward distribution subject to quality controls and minimum payout thresholds apply. Training contributions subject to review and must maintain minimum accuracy standards. Intentional degradation is prohibited.</p>
          </section>

          <section>
            <h4 className="text-xl font-medium mb-3">8. Liability and Warranties</h4>
            <p>Services provided "as is" without warranty. We are not liable for decisions made using our AI models, loss of data or profits, service interruptions, or third-party content or services. Maximum liability limited to fees paid.</p>
          </section>

          <section>
            <h4 className="text-xl font-medium mb-3">9. Changes to Terms</h4>
            <p>We may modify these terms with notice. Continued use constitutes acceptance. Material changes require explicit consent.</p>
          </section>

          <section>
            <h4 className="text-xl font-medium mb-3">10. Contact Information</h4>
            <p>For questions about these Terms:<br />
            Email: legal@glades.ai<br />
            </p>
          </section>
        </div>
      </div>
    );

        
      case 'contact':
        return (
          <div>
            <h3 className="text-xl font-semibold mb-4">Contact Us</h3>
            <form onSubmit={(e) => {
              e.preventDefault();
              // Handle form submission
              console.log('Form submitted');
            }} className="space-y-4">
              <div>
                <label htmlFor="name" className="block text-sm font-medium text-gray-700 mb-1">
                  Name
                </label>
                <input
                  type="text"
                  id="name"
                  className="w-full px-3 py-2 border border-gray-300 rounded-md"
                  required
                />
              </div>
              <div>
                <label htmlFor="subject" className="block text-sm font-medium text-gray-700 mb-1">
                  Subject
                </label>
                <input
                  type="subject"
                  id="email"
                  className="w-full px-3 py-2 border border-gray-300 rounded-md"
                  required
                  />
                  </div>
                  <div>
                    <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-1">
                      Email
                    </label>
                    <input
                      type="email"
                      id="email"
                      className="w-full px-3 py-2 border border-gray-300 rounded-md"
                      required

                />
              </div>
              <div>
                <label htmlFor="message" className="block text-sm font-medium text-gray-700 mb-1">
                  Message
                </label>
                <textarea
                  id="message"
                  className="w-full px-3 py-2 border border-gray-300 rounded-md"
                  rows="4"
                  required
                />
              </div>
              <button
                type="submit"
                className="w-full bg-green-500 text-white px-4 py-2 rounded-md hover:bg-green-600 transition"
              >
                Send Message
              </button>
            </form>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="fixed inset-0 bg-white z-50 overflow-y-auto">
      <div className="max-w-6xl mx-auto px-4 py-8">
        {/* Header with navigation and close button */}
        <div className="flex justify-between items-center mb-8 border-b pb-4">
          <nav className="flex space-x-6">
            <button
              onClick={() => setActiveSection('privacy')}
              className={`text-lg ${activeSection === 'privacy' ? 'text-green-500 font-semibold' : 'text-gray-600'}`}
            >
              Privacy Policy
            </button>
            <button
              onClick={() => setActiveSection('terms')}
              className={`text-lg ${activeSection === 'terms' ? 'text-green-500 font-semibold' : 'text-gray-600'}`}
            >
              Terms of Service
            </button>
            <button
              onClick={() => setActiveSection('contact')}
              className={`text-lg ${activeSection === 'contact' ? 'text-green-500 font-semibold' : 'text-gray-600'}`}
            >
              Contact Us
            </button>
          </nav>
          <button
            onClick={onClose}
            className="bg-gray-100 hover:bg-gray-200 text-gray-600 px-4 py-2 rounded-lg flex items-center space-x-2 transition"
          >
            <X className="w-5 h-5" />
            <span>Close</span>
          </button>
        </div>

        {/* Content area */}
        <div className="max-w-3xl mx-auto">
          {renderContent()}
        </div>
      </div>
    </div>
  );
};

const GallerySection = () => {
  const galleryItems = [
    {
      id: 1,
      image: "/images/ml.png", // Replace with your actual image paths
      title: "https://glades-ml.com",
      description: "Advanced neural network training visualization."
    },
    {
      id: 2,
      image: "/images/sharpe1.png",
      title: "Risk Metrics",
      description: "Sharpe ratio measures how much return an investment generates for each unit of risk taken."
                    
    },
    {
      id: 3,
      image: "/images/graph.png",
      title: "Data Analytics",
      description: "Comprehensive data analysis tools."
    },
    {
      id: 4,
      image: "/images/sharpe.png",
      title: "Risk Metrics",
      description: "Beta measures an investment's volatility relative to the market."
    },
    {
      id: 5,
      image: "/images/ellipse.png",
      title: "EllipseCreator",
      description: "A fun tool where you can play around creating ellipses and heatmaps."
    }
  ];

  return (
    <section id="gallery" className="bg-blue-50 py-16">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl md:text-4xl font-bold text-center mb-12">
          Gallery
        </h2>
        
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {galleryItems.map((item) => (
            <div 
              key={item.id}
              className="bg-white rounded-lg shadow-lg overflow-hidden hover:shadow-xl transition-shadow duration-300"
            >
              <div className="relative h-48 md:h-64 overflow-hidden">
                <img
                  src={item.image}
                  alt={item.title}
                  className="w-full h-full object-cover transform hover:scale-105 transition-transform duration-300"
                />
              </div>
              <div className="p-6">
                <h3 className="text-xl font-semibold mb-2">{item.title}</h3>
                <p className="text-gray-600">{item.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};



function GladesWebsite() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isFooterPageOpen, setIsFooterPageOpen] = useState(false);
  const [footerSection, setFooterSection] = useState('privacy');
  const termsOfServiceRef = useRef(null);
  const playToTrainRef = useRef(null);
  const featuresRef = useRef(null);
  const [email, setEmail] = useState('');
  const [subscribeStatus, setSubscribeStatus] = useState('');

  const scrollToFeatures = () => {
    featuresRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const handleSubscribe = (e) => {
    e.preventDefault();
    console.log('Subscribed:', email);
    setSubscribeStatus('Thanks for subscribing!');
    setEmail('');

  };


  return (
    <div className="font-sans text-gray-600">
    <header className="bg-gradient-to-r from-blue-600 to-green-600 text-white p-2 md:p-4 fixed w-full z-50">
      <nav className="container mx-auto">
        <div className="flex items-center justify-between">
          {/* Logo */}
          <button 
            onClick={() => {
              const element = document.getElementById('home');
              if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
              }
            }} 
            className="flex items-center hover:opacity-80 transition"
          >
            <img 
              src="logo-012.png"
              alt="GladesAI Logo" 
              className="w-8 h-8 md:w-16 md:h-12 object-contain"
            />
            <h1 className="text-lg md:text-xl font-bold ml-2">GladesAI</h1>
          </button>
  
          {/* Desktop Navigation - Now on the right */}
          <div className="hidden md:flex items-center space-x-6">
          <NavLink targetId="home">Home</NavLink>
          <NavLink targetId="About">About</NavLink>
          <NavLink targetId="play-to-train">Play to Train™</NavLink>
          <NavLink targetId="gallery">Gallery</NavLink>
          <a 
        href="https://github.com/Gattic/glades-ml" 
        target="_blank" 
        rel="noopener noreferrer"
        className="text-white hover:text-green-300 transition"
    >
        GitHub
    </a>

          <NavLink targetId="resources">Litepaper</NavLink>
</div>
  
          {/* Hamburger menu button */}
          <button className="md:hidden p-1" onClick={() => setIsMenuOpen(!isMenuOpen)}>
            <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
            </svg>
          </button>
        </div>
        
        {/* Mobile Navigation Menu */}
        {/* First, adjust the container for the mobile menu */}
        <div className={`${isMenuOpen ? 'block' : 'hidden'} md:hidden mt-2 absolute top-full left-0 right-0 bg-gradient-to-r from-blue-600 to-green-600 shadow-lg`}>
    <div className="flex flex-col text-center py-2 space-y-4">
        <NavLink targetId="home" onNavigate={() => setIsMenuOpen(false)}>Home</NavLink>
        <NavLink targetId="About" onNavigate={() => setIsMenuOpen(false)}>About</NavLink>
        <NavLink targetId="play-to-train" onNavigate={() => setIsMenuOpen(false)}>Play to Train™</NavLink>
        <NavLink targetId="gallery" onNavigate={() => setIsMenuOpen(false)}>Gallery</NavLink>
        <a 
            href="https://github.com/Gattic/glades-ml" 
            target="_blank" 
            rel="noopener noreferrer"
            className="text-white hover:text-green-300 transition py-2 text-center"
            onClick={() => setIsMenuOpen(false)}
        >
            GitHub
        </a>
        <a 
            href="www.google.com" 
            target="_blank" 
            rel="noopener noreferrer"
            className="text-white hover:text-green-300 transition py-2 text-center"
            onClick={() => setIsMenuOpen(false)}
        >
            Litepaper
        </a>
    </div>
</div>
      </nav>
    </header>

<section 
  id="home" 
  className="relative bg-cover bg-center text-white min-h-screen flex items-center pt-20 md:pt-16"
  style={{ backgroundImage: `url('/images/background1.png')` }}
>
  <div className="absolute inset-0 bg-black opacity-50"></div>
  <div className="container mx-auto text-center relative z-10 px-4">
    <h2 className="mb-4 md:mb-6">
      <span className="block text-3xl md:text-5xl font-bold text-green-400">Glades AI</span>
      <span className="block text-2xl md:text-4xl font-semibold mt-2 md:mt-4">Innovation in Machine Learning</span>
    </h2>
    <p className="text-lg md:text-xl mb-6 md:mb-8 max-w-2xl mx-auto">
    Advanced Portfolio Analytics & Custom AI Solutions    </p>
    <div className="flex flex-col md:flex-row justify-center space-y-4 md:space-y-0 md:space-x-4">
  <a 
    href="https://github.com/Gattic/gattic-installer"
    target="_blank"
    rel="noopener noreferrer"
    className="w-full md:w-auto bg-green-500 text-white px-4 py-2 rounded-full text-base font-semibold hover:bg-green-600 transition inline-block text-center"
  >
    Install NNCreator
  </a>
  <a 
    href="https://app.uniswap.org"
    target="_blank"
    rel="noopener noreferrer"
    className="w-full md:w-auto bg-blue-500 text-white px-4 py-2 rounded-full text-base font-semibold hover:bg-purple-600 transition inline-block text-center"
  >
    Buy $GLDS
  </a>
</div>
  </div>
</section>

<section 
        
        id="About" className="bg-blue-50 py-16"> 
        <div className="container mx-auto">
          <h2 className="text-3xl font-bold text-center mb-12">Products & Services</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <FeatureCard 
              Icon={Webhook} 
              title="Web API" 
              description="Access the power of the Glades engine anywhere via HTTP or HTTPS. Utilize our advanced mathmatical and AI models."
              details={
                <ul className="list-disc list-inside">
                  <li>Supports CVS and PNG output</li>
                  <li>Realtime data</li>
                  <li>Connect your portolio</li>
                </ul>
              }
            />

            <FeatureCard 
            Icon={Gamepad2}
            title="Play to Train" 
            description="Utilize your video games processing power to earn money by training AI."
            details={
            <ul className="list-disc list-inside">
              <li>Earn money by playing video games </li>
              <li>Unreal and unity support (coming soon)</li>
              <li>VR support</li>
              <li>Developers can earn too buy adding Glades to their video game</li>
              </ul>
            }
          />
            <FeatureCard 
              Icon={TrendingUp} 
              title="Trading Signals " 
              description="Advanced signals uniquely visualized. Enchance your perspective on the market with the power of Glades engine"
              details={
                <ul className="list-disc list-inside">
                  <li>Accessible via Web API, Discord and Telegram</li>
                  <li>Advanced forecasting using forecasting and predictions</li>
                  <li>Integration with multiple data sources</li>
                </ul>
              }
            />

            <FeatureCard 
              Icon={Waypoints} // First import Microscope from lucide-react
              title="NNCreator" 
              description="A lightweight and portable GUI that allows you to create and manage neural networks."
              details={
                <ul className="list-disc list-inside">
                  <li>Supports 32 bit and 64 bit systems</li>
                  <li>Load and save neural network states locally on your computer</li>
                  <li>Locally deployable decentralised backend</li>
                  <li>Various customisation options and visualizations</li>
                </ul>
              }
            />
            <FeatureCard 
              Icon={ChartScatter} 
              title="Portfolio Analytics" 
              description="Connect your portfolio to receive various statistics and analytics."
              details={
               <ul className="list-disc list-inside">
                <li>Receive unique risk perameters for your portfolio</li>
                <li>unique visualizations of your portfolio</li>
                <li>Automated report generation and insights</li>
              </ul>
            }
          />
            <FeatureCard 
              Icon={Bot} 
              title="AI Agents" 
              description="Create and deploy bots to complete various tasks."
              details={
                <ul className="list-disc list-inside">
                  <li>Use one of the many AI in our marketplace to perform various tasks</li>
                  <li>Use Our AI agent to scan documents for sentiment</li>
                  <li>Revenue sharing for model creators</li>
                  <li>Continuous model updates and improvements</li>
                </ul>
  }
/>
          </div>
        </div>
      </section>

      <section id="features" className="bg-blue-50 py-32"> {/* Changed background to light blue */}
  <div className="container mx-auto">
    <h2 className="text-4xl font-bold text-center mb-16">Our Vision</h2>
    <div className="bg-white p-20 rounded-lg shadow-md"> {/* Added this wrapper */}
      <div className="flex flex-col lg:flex-row items-center space-y-12 lg:space-y-0 lg:space-x-16">
        <div className="w-full lg:w-1/2">
          <p className="text-xl text-gray-700 mb-8">
            At Glades AI, we strive to broaden access to advanced AI technologies, making them attainable and actionable for people and businesses of all sizes.
          </p>
          <ul className="list-disc list-inside text-xl text-gray-700 space-y-4">
            <li>Deploy AI agents to assist you</li>
            <li>Earn money to train AI</li>
            <li>Train AI easily via Video games</li>
            <li>Advanced analytics and visualizations for all datasets </li>
          </ul>
        </div>
        <div className="w-full lg:w-1/2">
          <img src="/images/vision-image.png" alt="Our Vision" className="rounded-lg shadow-md" />
        </div>
      </div>
    </div>
  </div>
</section>

      <section id="play-to-train" ref={playToTrainRef} className="bg-blue-50 py-16">
        <div className="container mx-auto text-center">
          <h2 className="text-4xl font-bold mb-8 text-black-800">Play to Train™ with GladesAI</h2>
          <div className="bg-white p-8 rounded-lg shadow-lg">
            <Gamepad className="w-20 h-20 text-green-500 mx-auto mb-6" />
            <p className="text-xl text-gray-700 mb-6">
            Level up your neural network training with our immersive Play to Train AR/VR experience. 
            Contribute to model advancement through engaging gameplay, and earn rewards for your efforts!
            </p>
            <ul className="text-left text-lg text-gray-600 mb-8 space-y-2">
              <li>✓ <strong>Turn your AI training skills into real earnings! Complete challenges from top companies and get paid for your expertise.</strong></li>
              <li>✓ <strong>Create and sell your own AI models in our marketplace. Let your innovations benefit others and earn passive income</strong></li>
              <li>✓ <strong>Be among the first to test and use the latest AI models you've helped create. Shape the future of technology!</strong></li>
              
            </ul>
            <button className="bg-green-500 text-white px-8 py-4 rounded-full text-xl font-semibold hover:bg-purple-600 transition">
              Coming soon
            </button>
          </div>
          
        </div>
      </section>

      <GallerySection /> 


              <section id="resources" className="bg-blue-50 py-16">
          <div className="container mx-auto">
            <h2 className="text-3xl font-bold text-center mb-12"></h2>
            
            <div className="bg-white-500 p-12 rounded-lg mb-16">
              <HorizontalRoadmap />
            </div>
            
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-16">
              <ResourceCard 
                Icon={FileText}
                title="Technical Litepaper" 
                description="Dive deep into the technical aspects of our neural network architecture."
                buttonText="View Litepaper"
                buttonLink="https://your-litepaper-url.com"
      />
            <ResourceCard 
            Icon={ShieldCheck}
            title="Security Audit Reports" 
            description="Review our latest security audit report and privacy safeguards."
            buttonText="View Audit Report"
            buttonLink="https://your-security-audit-url.com"  /* Add your actual security audit URL here */
          />
            <div ref={termsOfServiceRef}>
              <ResourceCard 
                Icon={FileText}
                title="Glades Bot Terms of Service" 
                description="Read the terms of service before using our Glades Discord Bot."
                buttonText="View Terms"
                isCollapsed={true}
                showAcceptConditions={true}
                extraContent={
            <div>
              <h4 className="font-bold mb-2">Glades Bot Terms of Service</h4>
              <p>1. Acceptance of Terms: By using the Glades Discord Bot, you agree to be bound by these Terms of Service.</p>
              <p>2. Use of Service: The Glades Bot is provided for informational purposes only. Do not rely on it for financial advice.</p>
              <p>3. User Responsibilities: You are responsible for all activity that occurs under your Discord account in relation to the Glades Bot.</p>
              <p>4. Prohibited Actions: You may not use the Glades Bot for any illegal or unauthorized purpose.</p>
              <p>5. Disclaimer of Warranties: The Glades Bot is provided "as is" without any warranties.</p>
              <p>6. Limitation of Liability: We shall not be liable for any indirect, incidental, special, consequential or punitive damages.</p>
              <p>7. Changes to Terms: We reserve the right to modify these terms at any time.</p>
              <p>8. Governing Law: These terms shall be governed by and construed in accordance with the laws of [Your Jurisdiction].</p>
            </div>
          }
        />
      </div>
    </div>
  </div>
</section>

    <ExtendedFAQSection />


{/* Then, the Footer with links */}
<section className="bg-gradient-to-r from-blue-600 to-green-600 text-white py-16">
  <div className="container mx-auto text-center px-4">
    <h2 className="text-3xl md:text-4xl font-bold mb-4">
      Stay Updated
    </h2>
    <p className="text-lg mb-8 max-w-2xl mx-auto">
      Subscribe to receive updates about new features, AI insights, and platform news.
    </p>
    <form 
      onSubmit={handleSubscribe}
      className="max-w-md mx-auto flex flex-col md:flex-row gap-4"
    >
      <input
        type="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        placeholder="Enter your email"
        className="px-4 py-3 rounded-lg flex-grow text-gray-800 focus:outline-none focus:ring-2 focus:ring-green-400"
        required
      />
      <button 
        type="submit"
        className="bg-green-500 hover:bg-green-600 px-8 py-3 rounded-lg transition duration-300 font-semibold"
      >
        Subscribe
      </button>
    </form>
    {subscribeStatus && (
      <p className="mt-4 text-green-300">{subscribeStatus}</p>
    )}
  </div>
</section>

<footer className="bg-blue-50 dark:bg-gray-900 text-gray dark:text-gray-400 py-4">
  <div className="container mx-auto text-center">
    <div className="flex flex-wrap justify-center items-center gap-4 md:gap-6">
      <p className="text-sm mb-0">
        &copy; 2024 GladesAI
      </p>
      <button 
        onClick={() => {
          setFooterSection('privacy');
          setIsFooterPageOpen(true);
        }} 
        className="text-sm text-green-400 hover:text-green-300 dark:text-green-500 dark:hover:text-green-400 transition"
      >
        Privacy
      </button>
      <button 
        onClick={() => {
          setFooterSection('terms');
          setIsFooterPageOpen(true);
        }} 
        className="text-sm text-green-400 hover:text-green-300 dark:text-green-500 dark:hover:text-green-400 transition"
      >
        Terms
      </button>
      <button 
        onClick={() => {
          setFooterSection('contact');
          setIsFooterPageOpen(true);
        }} 
        className="text-sm text-green-400 hover:text-green-300 dark:text-green-500 dark:hover:text-green-400 transition"
      >
      
  Contact
</button>
<a 
        href="https://github.com/Gattic/glades-ml" 
        target="_blank" 
        rel="noopener noreferrer"
        className="text-sm text-green-400 hover:text-green-300 dark:text-green-500 dark:hover:text-green-400 transition"
      >
        GitHub
      </a>
      <a 
        href="https://discord.gg/6u3JartbQQ" 
        target="_blank" 
        rel="noopener noreferrer"
        className="text-sm text-green-400 hover:text-green-300 dark:text-green-500 dark:hover:text-green-400 transition"
      >
         Discord
</a>
<a 
        href="https://github.com/Gattic/litepaper" // This is where the URL needs to go
        target="_blank" 
        rel="noopener noreferrer"
        className="text-sm text-green-400 hover:text-green-300 dark:text-green-500 dark:hover:text-green-400 transition"
      >
        Litepaper
</a>
    </div>
  </div>
</footer>

{/* FooterPage component */}
<FooterPage
  isOpen={isFooterPageOpen}
  onClose={() => setIsFooterPageOpen(false)}
  initialSection={footerSection}
/>
    </div>
  );
}

export default GladesWebsite;
